<template>
  <div class="home">
   
    <v-container>
    <v-row style="height:300px;">
      <v-col></v-col>
    
    <v-col><div style="text-align:center; border-radius: 5px 5px 5px 5px;" >  <img src="../assets/duncan.jpeg" height="300px" style="box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-webkit-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-moz-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);" /></div></v-col>
    <v-col></v-col>
    </v-row>
    <v-row style="height:50px;">

    </v-row>
    <v-row align="center">
      <h2>About</h2>
    
    </v-row>
    <v-row >
      <v-col style="min-width:250px;">
        <v-card style="box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-webkit-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-moz-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);">
          <v-card-title>Personal Details</v-card-title>
          <v-card-text>
            <v-row cols="2">
              <v-col>Name:</v-col>
              <v-col>Duncan McIntyre</v-col>
              
              
            </v-row>
            <v-row cols="2">
              <v-col>Nationality:</v-col>
              <v-col>South African</v-col>
              
              
            </v-row>
            <v-row cols="2">
              <v-col>City Of Residence:</v-col>
              <v-col>Pretoria, South Africa</v-col>
            </v-row>
            <v-row cols="2">
              <v-col>Drivers License:</v-col>
              <v-col>Yes</v-col>
            </v-row>
          </v-card-text>
        </v-card>
       
      </v-col>
      <v-col style="min-width:350px;">
        <v-card>
          <v-card-title>The Story</v-card-title>
  <v-card-text><p>I have always been interested in technology. From the age of 4, I would open and repair VCRs and play around on our 286 IBM computer.</p>
  <p>I started programming with Turbo Pascal from the age 0f 10, creating simple login and text based data storage systems</p>
  <p>At age 13 I started using Visual Basic 6 and my love of application development begin. New technologies have always fascinated me, and the trying see what the limitations of the tech was in order to fully understand where the proper application of the technology would lie.</p>  
  <p>I have also developed a love for robotics, automation and IOT. </p>
  <p>My second love was writing, directing and producing motion pictures and I had the opportunity to study Film and Video at the prestigious Full Sail University in Orlando, Florida.</p>
  <p>I obtained my Associate of Science in Film and Video Production and started a successful Production Company, but my heart still leaned towards software development</p>
  <p>I sold my company and decided to pursue my B.Comp in Software Engineering at Belgium Campus</p> <p>I worked fulltime and studied fulltime finally obtained my NQF8 degree in 2017</p>
  <p>I worked at several companies trying to identify my niche position that would suite my vast skillset</p>
  <p>Through hard work, dedication and loyalty I became Chief Technology Officer, the position that I currently hold.</p>
  </v-card-text>
        </v-card>
</v-col>
<v-col>
<v-card>
 <v-card-title>Duncan's Favourite Saying</v-card-title>
 <v-card-text>"Do not reinvent the wheel. Build a better wheel."</v-card-text>
</v-card>
</v-col>

    </v-row>
  </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'About',
  components: {
  
  }
}
</script>
<style scoped>
.home{
  background-image: url(../assets/19366.jpg);
  background-repeat: repeat-y;
  background-size: cover;
  
}
</style>

//