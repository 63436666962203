<template>
    <div class="home">
     
      <v-container>
      <v-row style="height:300px;">
        <v-col></v-col>
      
      <v-col><div style="text-align:center; border-radius: 5px 5px 5px 5px;" >  <img src="../assets/duncan.jpeg" height="300px" style="box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-webkit-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
-moz-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);"  /></div></v-col>
      <v-col></v-col>
      </v-row>
      <v-row style="height:50px;">
  
      </v-row>
      <v-row align="center">
        <h2>Education</h2>
        <carousel-3d :controls-visible="true" :controls-prev-html="'&#10092; '" :controls-next-html="'&#10093;'" 
                 :controls-width="30" :controls-height="60" :clickable="false" :width="600" :height="600">
    <slide :index="0" >
     <v-card style="height:100%; border-radius: 10px;
     background-image: url(../assets/19366.jpg);
    background-repeat: repeat-y;
    background-size: cover;  
   ">
      <v-card-title style="overflow-y:scroll" >
      
        <h5 style="word-wrap:normal">Bachelor of Computing (NQF8)</h5>
      </v-card-title>
      <v-card-subtitle>  <h6>Completed 2017</h6></v-card-subtitle>
      <v-card-text class="home" style="height:100%;">
        <v-row>
          <v-col>
            Institution:
          </v-col>
          <v-col>
           Beglium Campus
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Major:
          </v-col>
          <v-col>
            <v-row>
              <v-col>Software Engineering</v-col>
            </v-row>
           
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Location:
          </v-col>
          <v-col>
            <v-row>
              <v-col>Pretoria, South Africa</v-col>
            </v-row>
           
          </v-col>
        </v-row>
      
      </v-card-text>
     </v-card>
    </slide>
    <slide :index="1">
        <v-card style="height:100%; border-radius: 10px;
     background-image: url(../assets/19366.jpg);
    background-repeat: repeat-y;
    background-size: cover;  
   ">
      <v-card-title style="overflow-y:scroll" >
      
        <h5 style="word-wrap:normal">Associate of Science (NQF6)</h5>
      </v-card-title>
      <v-card-subtitle>  <h6>Completed 2005</h6></v-card-subtitle>
      <v-card-text class="home" style="height:100%;">
        <v-row>
          <v-col>
            Institution:
          </v-col>
          <v-col>
           Full Sail University
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Major:
          </v-col>
          <v-col>
            <v-row>
              <v-col>Film and Video Production</v-col>
            </v-row>
           
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Location:
          </v-col>
          <v-col>
            <v-row>
              <v-col>Winter Park, FL, USA</v-col>
            </v-row>
           
          </v-col>
        </v-row>
      
      </v-card-text>
     </v-card>
     
    </slide>
    <slide :index="2">
        <v-card style="height:100%; border-radius: 10px;
     background-image: url(../assets/19366.jpg);
    background-repeat: repeat-y;
    background-size: cover;  
   ">
      <v-card-title style="overflow-y:scroll" >
      
        <h5 style="word-wrap:normal">High School</h5>
      </v-card-title>
      <v-card-subtitle>  <h6>Completed 2003</h6></v-card-subtitle>
      <v-card-text class="home" style="height:100%;">
        <v-row>
          <v-col>
            Institution:
          </v-col>
          <v-col>
            St. Alban's College
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Major:
          </v-col>
          <v-col>
            <v-row>
              <v-col>High School</v-col>
            </v-row>
           
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Location:
          </v-col>
          <v-col>
            <v-row>
              <v-col>Pretoria, South Africa</v-col>
            </v-row>
           
          </v-col>
        </v-row>
      
      </v-card-text>
     </v-card>
   
    </slide>
    
  </carousel-3d>
      </v-row>
      <v-row style="height:100px;"></v-row>
    </v-container>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { Carousel3d, Slide } from 'vue-carousel-3d';
  export default {
    name: 'Home',
    components: {
     Carousel3d,Slide
    }
  }
  </script>
  <style scoped>
  .home{
    background-image: url(../assets/19366.jpg);
    background-repeat: repeat-y;
    background-size: cover;
    
  }
  </style>
  <style lang="scss">
  .carousel-3d-container {
    
    .carousel-3d-slide {
     border:2px solid black;
      
      .title { font-size: 22px; }
    }
  }
  
  </style>
  //