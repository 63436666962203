<template>
    <div class="home">
     
      <v-container>
      <v-row style="height:300px;" cols="1">
        <v-col></v-col>
      
      <v-col><div style="text-align:center; border-radius: 5px 5px 5px 5px;" >  <img src="../assets/duncan.jpeg" height="300px" style="box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
  -webkit-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);
  -moz-box-shadow: 15px 19px 12px 7px rgba(0,0,0,0.75);" /></div></v-col>
      <v-col></v-col>
      </v-row>
      <v-row style="height:50px;">
  
      </v-row>
      <v-row align="center">
        <h2>Skills</h2>
        <div>
    
   </div>
      </v-row>
      <v-row> <apexchart width="500" type="bar" :options="chartOptions" :series="series"></apexchart></v-row>
     
    </v-container>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  export default {
    name: 'Skills',
    components: {
    
    },
    data: function() {
      return {
        chartOptions: {
            chart: {
                type: 'bar',
              height: 380
            },
            xaxis: {
              type: 'category',
              labels: {
                formatter: function(val) {
                  return val.substring(0,val.indexOf('&&'))
                }
              },
              group: {
                style: {
                  fontSize: '20px',
                  fontWeight: 700
                },
               
              }
            },
            title: {
                text: 'Years Of Professional Experience',
            },
            tooltip: {
              x: {
                formatter: function(val) {
                  return val.substring(val.indexOf('&&')+2)
                }  
              }
            },
          },
          series: [{
            name: "Experience",
            data: [{
              x: 'C#&&C#',
              y: 10
            },
            {
              x: 'WebAPI&&WebAPI',
              y: 3
            },
            {
              x: '.Net Core 3.1&&.Net Core 3.1',
              y: 10
            },
            {
              x: '.Net 5, 6&&.Net 5, 6',
              y: 2
            },
            
             {
              x: 'VS&&Visual Studio IDE',
              y: 10
            }, {
              x: 'T-SQL&&T-SQL',
              y: 10
            }, {
              x: 'SSMS&&SQL Server Management Studio',
              y: 8
            }, {
              x: 'Python&&Python',
              y: 5
            }, {
              x: 'PyCharm&&PyCharm',
              y: 5
            }, {
              x: 'Java&&Java',
              y: 5
            }
            , {
              x: 'JS&&JavaScript',
              y: 10
            },
             {
              x: 'VueJS&&VueJS',
              y: 3
            },
            {
              x: 'VS Code&&Visual Studio Code IDE',
              y: 5
            },
            {
              x: 'Arduino C&&Arduino C',
              y: 5
            },
            {
              x: 'Android Java&&Android Java',
              y: 7
            },
            {
              x: 'Message Queuing&&Zero MQ, Rabbit MQ',
              y: 3
            },
            {
              x: 'JIRA&&JIRA',
              y: 4
            },
            {
              x: 'FreshDesk&&FreshDesk',
              y: 2
            },
            {
              x: 'Robotics&&Robotics',
              y: 7
            },
            {
              x: 'ML , AI&&Machine Learning, Artificail Intelligence',
              y: 4
            },
            
        ]
          }],
      }
    },
  }
  </script>
  <style scoped>
  .home{
    background-image: url(../assets/19366.jpg);
    background-repeat: repeat-y;
    background-size: cover;
    
  }
  </style>
  
  //